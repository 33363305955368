import { CaratDown } from 'common/icons';
import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { colors } from 'component-library/styles/colors';
import React, { FC, ReactNode, useState } from 'react';
import styled from 'styled-components';

interface StyledFlexProps {
  showExpandedContent: boolean;
}

const StyledFlex = styled(Flex)<StyledFlexProps>`
  padding: 10px;
  border-bottom: ${({ showExpandedContent }) =>
    showExpandedContent ? `1px dotted ${colors.navyBlue}` : 'none'};
`;

const CaratRight = styled(CaratDown)`
  transform: rotate(-90deg);
`;

const StyledExpandableSection = styled.div`
  border: 1px solid ${colors.navyBlue};
  border-radius: 3px;
  cursor: pointer;
`;

interface ExpandableSectionProps {
  children: ReactNode[];
}

export const ExpandableSection: FC<ExpandableSectionProps> = ({ children }) => {
  const [showExpandedContent, setShowExpandedContent] = useState(false);

  return (
    <StyledExpandableSection
      onClick={() => setShowExpandedContent((prev) => !prev)}
    >
      <StyledFlex
        justifyContent="space-between"
        alignItems="center"
        showExpandedContent={showExpandedContent}
      >
        {children[0]}
        {showExpandedContent ? <CaratDown /> : <CaratRight />}
      </StyledFlex>
      {showExpandedContent && children[1]}
    </StyledExpandableSection>
  );
};

export const ExpandableSectionHeading = styled(Body).attrs({
  medium: true,
  variant: 2,
})``;

export const ExpandableSectionBody = styled.div`
  padding: 10px;
`;
