import Layout from 'common/layouts/Layout';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import {
  ExpandableSection,
  ExpandableSectionBody,
  ExpandableSectionHeading,
} from 'components/pages/legal';
import {
  H2,
  LegalSection,
  LegalWrapper,
  Li,
  Paragraph,
  Ul,
} from 'components/pages/legal/shared';
import { SEO } from 'components/SEO';
import React, { FC } from 'react';

interface TermsAndConditionsProps {}

const TermsAndConditions: FC<TermsAndConditionsProps> = () => (
  <Layout>
    <Navigation />
    <PageWrapper>
      <LegalWrapper>
        <LegalSection>
          <H2>
            Terms and Conditions of <b>Retirable, Inc.</b>
          </H2>
          <Paragraph marginBottom={20}>
            These Terms govern:
            <Ul>
              <Li>the use of Retirable, Inc., and,</Li>
              <Li>
                any other related Agreement or legal relationship with the Owner
              </Li>
            </Ul>
            in a legally binding way. Capitalized words are defined in the
            relevant dedicated section of this document.
          </Paragraph>
          <Paragraph>The User must read this document carefully.</Paragraph>
        </LegalSection>
        <LegalSection>
          <Paragraph marginBottom={20}>
            Retirable, Inc. is provided by:
          </Paragraph>
          <Paragraph>Retirable, Inc.</Paragraph>
          <Paragraph marginBottom={20}>
            41 E 11th St, 11th Floor, New York, NY 10003, USA
          </Paragraph>
          <Paragraph marginBottom={20}>
            <b>Owner contact email:</b> privacy@retirable.com
          </Paragraph>
          <Paragraph marginBottom={20}>
            &quot;Retirable, Inc.&quot; refers to
            <Ul>
              <Li>
                this website, including its subdomains and any other website
                through which the Owner makes its Service available;
              </Li>
              <Li>
                applications for mobile, tablet and other smart device systems;
              </Li>
              <Li>the Application Program Interfaces (API);</Li>
              <Li>the Service;</Li>
              <Li>
                any applications, sample and content files, source code,
                scripts, instruction sets or software included as part of the
                Service, as well as any related documentation;
              </Li>
            </Ul>
            The following documents are incorporated by reference into these
            Terms:
          </Paragraph>
          <H2 medium>What the User should know at a glance</H2>
          <Paragraph>
            <Ul>
              <Li>
                The Service/Retirable, Inc. is only intended for Consumers.
              </Li>
            </Ul>
          </Paragraph>
        </LegalSection>
        <LegalSection>
          <H2 medium>Terms of Use</H2>
          <Paragraph marginBottom={20}>
            Unless otherwise specified, the terms of use detailed in this
            section apply generally when using Retirable, Inc..
          </Paragraph>
          <Paragraph marginBottom={20}>
            Single or additional conditions of use or access may apply in
            specific scenarios and in such cases are additionally indicated
            within this document.
          </Paragraph>
          <Paragraph marginBottom={56}>
            By using Retirable, Inc., Users confirm to meet the following
            requirements:
            <Ul>
              <Li>Users must qualify as Consumers;</Li>
              <Li>
                Users aren&apos;t located in a country that is subject to a U.S.
                Government embargo, or that has been designated by the U.S.
                Government as a “terrorist-supporting” country;
              </Li>
              <Li>
                Users aren&apos;t listed on any U.S. Government list of
                prohibited or restricted parties;
              </Li>
            </Ul>
          </Paragraph>
          <H2 medium>Content on Retirable, Inc.</H2>
          <Paragraph marginBottom={20}>
            Unless where otherwise specified or clearly recognizable, all
            content available on Retirable, Inc. is owned or provided by the
            Owner or its licensors.
          </Paragraph>
          <Paragraph>
            The Owner undertakes its utmost effort to ensure that the content
            provided on Retirable, Inc. infringes no applicable legal provisions
            or third-party rights. However, it may not always be possible to
            achieve such a result.
          </Paragraph>
          <Paragraph marginBottom={20}>
            In such cases, without prejudice to any legal prerogatives of Users
            to enforce their rights, Users are kindly asked to preferably report
            related complaints using the contact details provided in this
            document.
          </Paragraph>
          <Paragraph marginBottom={20} medium>
            Rights regarding content on Retirable, Inc. - All rights reserved
          </Paragraph>
          <Paragraph marginBottom={20}>
            The Owner holds and reserves all intellectual property rights for
            any such content.
          </Paragraph>
          <Paragraph marginBottom={20}>
            Users may not therefore use such content in any way that is not
            necessary or implicit in the proper use of the Service.
          </Paragraph>
          <Paragraph marginBottom={20}>
            In particular, but without limitation, Users may not copy, download,
            share (beyond the limits set forth below), modify, translate,
            transform, publish, transmit, sell, sublicense, edit,
            transfer/assign to third parties or create derivative works from the
            content available on Retirable, Inc., nor allow any third party to
            do so through the User or their device, even without the User&apos;s
            knowledge.
          </Paragraph>
          <Paragraph marginBottom={20}>
            Where explicitly stated on Retirable, Inc., the User may download,
            copy and/or share some content available through Retirable, Inc. for
            its sole personal and non-commercial use and provided that the
            copyright attributions and all the other attributions requested by
            the Owner are correctly implemented.
          </Paragraph>
          <Paragraph marginBottom={56}>
            Any applicable statutory limitation or exception to copyright shall
            stay unaffected.
          </Paragraph>
          <H2 medium>Access to external resources</H2>
          <Paragraph marginBottom={20}>
            Through Retirable, Inc. Users may have access to external resources
            provided by third parties. Users acknowledge and accept that the
            Owner has no control over such resources and is therefore not
            responsible for their content and availability.
          </Paragraph>
          <Paragraph marginBottom={56}>
            Conditions applicable to any resources provided by third parties,
            including those applicable to any possible grant of rights in
            content, result from each such third parties&apos; terms and
            conditions or, in the absence of those, applicable statutory law.
          </Paragraph>
          <H2 medium>Acceptable use</H2>
          <Paragraph marginBottom={20}>
            Retirable, Inc. and the Service may only be used within the scope of
            what they are provided for, under these Terms and applicable law.
          </Paragraph>
          <Paragraph marginBottom={20}>
            Users are solely responsible for making sure that their use of
            Retirable, Inc. and/or the Service violates no applicable law,
            regulations or third-party rights.
          </Paragraph>
          <Paragraph marginBottom={56}>
            Therefore, the Owner reserves the right to take any appropriate
            measure to protect its legitimate interests including by denying
            Users access to Retirable, Inc. or the Service, terminating
            contracts, reporting any misconduct performed through Retirable,
            Inc. or the Service to the competent authorities — such as judicial
            or administrative authorities - whenever Users engage or are
            suspected to engage in any of the following activities:
            <Ul>
              <Li>violate laws, regulations and/or these Terms;</Li>
              <Li>infringe any third-party rights;</Li>
              <Li>
                considerably impair the Owner&apos;s legitimate interests;
              </Li>
              <Li>
                considerably impair the Owner&apos;s legitimate interests;
              </Li>
            </Ul>
          </Paragraph>
          <H2 medium>API usage terms</H2>
          <Paragraph marginBottom={56}>
            Users may access their data relating to Retirable, Inc. via the
            Application Program Interface (API). Any use of the API, including
            use of the API through a third-party product/service that accesses
            Retirable, Inc., is bound by these Terms and, in addition, by the
            following specific terms:
            <Ul>
              <Li>
                the User expressly understands and agrees that the Owner bears
                no responsibility and shall not be held liable for any damages
                or losses resulting from the User&apos;s use of the API or their
                use of any third-party products/services that access data
                through the API.
              </Li>
            </Ul>
          </Paragraph>
          <H2 medium>Liability and indemnification</H2>
          <H2 medium>US Users</H2>
          <Paragraph marginBottom={20} medium>
            Disclaimer of Warranties
          </Paragraph>
          <Paragraph marginBottom={20} medium>
            Retirable, Inc. is provided strictly on an “as is” and “as
            available” basis. Use of the Service is at Users&apos; own risk. To
            the maximum extent permitted by applicable law, the Owner expressly
            disclaims all conditions, representations, and warranties — whether
            express, implied, statutory or otherwise, including, but not limited
            to, any implied warranty of merchantability, fitness for a
            particular purpose, or non-infringement of third-party rights. No
            advice or information, whether oral or written, obtained by user
            from owner or through the Service will create any warranty not
            expressly stated herein.
          </Paragraph>
          <Paragraph marginBottom={20} medium>
            Without limiting the foregoing, the Owner, its subsidiaries,
            affiliates, licensors, officers, directors, agents, co-branders,
            partners, suppliers and employees do not warrant that the content is
            accurate, reliable or correct; that the Service will meet
            Users&apos; requirements; that the Service will be available at any
            particular time or location, uninterrupted or secure; that any
            defects or errors will be corrected; or that the Service is free of
            viruses or other harmful components. Any content downloaded or
            otherwise obtained through the use of the Service is downloaded at
            users own risk and users shall be solely responsible for any damage
            to Users&apos; computer system or mobile device or loss of data that
            results from such download or Users&apos; use of the Service.
          </Paragraph>
          <Paragraph marginBottom={20} medium>
            The Owner does not warrant, endorse, guarantee, or assume
            responsibility for any product or service advertised or offered by a
            third party through the Service or any hyperlinked website or
            service, and the Owner shall not be a party to or in any way monitor
            any transaction between Users and third-party providers of products
            or services.
          </Paragraph>
          <Paragraph marginBottom={20} medium>
            The Service may become inaccessible or it may not function properly
            with Users&apos; web browser, mobile device, and/or operating
            system. The owner cannot be held liable for any perceived or actual
            damages arising from Service content, operation, or use of this
            Service.
          </Paragraph>
          <Paragraph marginBottom={56} medium>
            Federal law, some states, and other jurisdictions, do not allow the
            exclusion and limitations of certain implied warranties. The above
            exclusions may not apply to Users. This Agreement gives Users
            specific legal rights, and Users may also have other rights which
            vary from state to state. The disclaimers and exclusions under this
            agreement shall not apply to the extent prohibited by applicable
            law.
          </Paragraph>
          <Paragraph marginBottom={20} medium>
            Limitations of liability
          </Paragraph>
          <Paragraph marginBottom={20}>
            To the maximum extent permitted by applicable law, in no event shall
            the Owner, and its subsidiaries, affiliates, officers, directors,
            agents, co-branders, partners, suppliers and employees be liable for
            <Ul>
              <Li>
                any indirect, punitive, incidental, special, consequential or
                exemplary damages, including without limitation damages for loss
                of profits, goodwill, use, data or other intangible losses,
                arising out of or relating to the use of, or inability to use,
                the Service; and
              </Li>
              <Li>
                any damage, loss or injury resulting from hacking, tampering or
                other unauthorized access or use of the Service or User account
                or the information contained therein;
              </Li>
              <Li>any errors, mistakes, or inaccuracies of content;</Li>
              <Li>
                personal injury or property damage, of any nature whatsoever,
                resulting from User access to or use of the Service;
              </Li>
              <Li>
                any unauthorized access to or use of the Owner&apos;s secure
                servers and/or any and all personal information stored therein;
              </Li>
              <Li>
                any interruption or cessation of transmission to or from the
                Service;
              </Li>
              <Li>
                any bugs, viruses, trojan horses, or the like that may be
                transmitted to or through the Service;
              </Li>
              <Li>
                any errors or omissions in any content or for any loss or damage
                incurred as a result of the use of any content posted, emailed,
                transmitted, or otherwise made available through the Service;
                and/or
              </Li>
              <Li>
                the defamatory, offensive, or illegal conduct of any User or
                third party. In no event shall the Owner, and its subsidiaries,
                affiliates, officers, directors, agents, co-branders, partners,
                suppliers and employees be liable for any claims, proceedings,
                liabilities, obligations, damages, losses or costs in an amount
                exceeding the amount paid by User to the Owner hereunder in the
                preceding 12 months, or the period of duration of this agreement
                between the Owner and User, whichever is shorter.
              </Li>
            </Ul>
            This limitation of liability section shall apply to the fullest
            extent permitted by law in the applicable jurisdiction whether the
            alleged liability is based on contract, tort, negligence, strict
            liability, or any other basis, even if company has been advised of
            the possibility of such damage.
          </Paragraph>
          <Paragraph medium marginBottom={56}>
            Some jurisdictions do not allow the exclusion or limitation of
            incidental or consequential damages, therefore the above limitations
            or exclusions may not apply to User. The terms give User specific
            legal rights, and User may also have other rights which vary from
            jurisdiction to jurisdiction. The disclaimers, exclusions, and
            limitations of liability under the terms shall not apply to the
            extent prohibited by applicable law.
          </Paragraph>
          <Paragraph medium marginBottom={20}>
            Indemnification
          </Paragraph>
          <Paragraph marginBottom={56}>
            The User agrees to defend, indemnify and hold the Owner and its
            subsidiaries, affiliates, officers, directors, agents, co-branders,
            partners, suppliers and employees harmless from and against any and
            all claims or demands, damages, obligations, losses, liabilities,
            costs or debt, and expenses, including, but not limited to, legal
            fees and expenses, arising from
            <Ul>
              <Li>
                User&apos;s use of and access to the Service, including any data
                or content transmitted or received by User;
              </Li>
              <Li>
                User&apos;s violation of these terms, including, but not limited
                to, User&apos;s breach of any of the representations and
                warranties set forth in these terms;
              </Li>
              <Li>
                User&apos;s violation of any third-party rights, including, but
                not limited to, any right of privacy or intellectual property
                rights;
              </Li>
              <Li>
                User&apos;s violation of any statutory law, rule, or regulation;
              </Li>
              <Li>
                any content that is submitted from User&apos;s account,
                including third party access with User&apos;s unique username,
                password or other security measure, if applicable, including,
                but not limited to, misleading, false, or inaccurate
                information;
              </Li>
              <Li>User&apos;s wilful misconduct; or</Li>
              <Li>
                statutory provision by User or its affiliates, officers,
                directors, agents, co-branders, partners, suppliers and
                employees to the extent allowed by applicable law.
              </Li>
            </Ul>
          </Paragraph>
          <H2 medium>Common provisions</H2>
          <Paragraph marginBottom={20} medium>
            No Waiver
          </Paragraph>
          <Paragraph marginBottom={56}>
            The Owner&apos;s failure to assert any right or provision under
            these Terms shall not constitute a waiver of any such right or
            provision. No waiver shall be considered a further or continuing
            waiver of such term or any other term.
          </Paragraph>
          <Paragraph marginBottom={20} medium>
            Service interruption
          </Paragraph>
          <Paragraph marginBottom={20}>
            To ensure the best possible service level, the Owner reserves the
            right to interrupt the Service for maintenance, system updates or
            any other changes, informing the Users appropriately.
          </Paragraph>
          <Paragraph marginBottom={20}>
            Within the limits of law, the Owner may also decide to suspend or
            terminate the Service altogether. If the Service is terminated, the
            Owner will cooperate with Users to enable them to withdraw Personal
            Data or information in accordance with applicable law.
          </Paragraph>
          <Paragraph marginBottom={56}>
            Additionally, the Service might not be available due to reasons
            outside the Owner&apos;s reasonable control, such as “force majeure”
            (eg. labor actions, infrastructural breakdowns or blackouts etc).
          </Paragraph>
          <Paragraph marginBottom={20} medium>
            Service reselling
          </Paragraph>
          <Paragraph marginBottom={56}>
            Users may not reproduce, duplicate, copy, sell, resell or exploit
            any portion of Retirable, Inc. and of its Service without the
            Owner&apos;s express prior written permission, granted either
            directly or through a legitimate reselling programme.
          </Paragraph>
          <Paragraph medium marginBottom={20}>
            Privacy policy
          </Paragraph>
          <Paragraph marginBottom={56}>
            To learn more about the use of their Personal Data, Users may refer
            to the privacy policy of Retirable, Inc..
          </Paragraph>
          <Paragraph medium marginBottom={20}>
            Intellectual property rights
          </Paragraph>
          <Paragraph marginBottom={20}>
            Without prejudice to any more specific provision of these Terms, any
            intellectual property rights, such as copyrights, trademark rights,
            patent rights and design rights related to Retirable, Inc. are the
            exclusive property of the Owner or its licensors and are subject to
            the protection granted by applicable laws or international treaties
            relating to intellectual property.
          </Paragraph>
          <Paragraph marginBottom={56}>
            All trademarks — nominal or figurative — and all other marks, trade
            names, service marks, word marks, illustrations, images, or logos
            appearing in connection with Retirable, Inc. are, and remain, the
            exclusive property of the Owner or its licensors and are subject to
            the protection granted by applicable laws or international treaties
            related to intellectual property.
          </Paragraph>
          <Paragraph marginBottom={20} medium>
            Changes to these Terms
          </Paragraph>
          <Paragraph marginBottom={20}>
            The Owner reserves the right to amend or otherwise modify these
            Terms at any time. In such cases, the Owner will appropriately
            inform the User of these changes.
          </Paragraph>
          <Paragraph marginBottom={20}>
            Such changes will only affect the relationship with the User for the
            future.
          </Paragraph>
          <Paragraph marginBottom={20}>
            The continued use of the Service will signify the User&apos;s
            acceptance of the revised Terms. If Users do not wish to be bound by
            the changes, they must stop using the Service. Failure to accept the
            revised Terms, may entitle either party to terminate the Agreement.
          </Paragraph>
          <Paragraph marginBottom={56}>
            The applicable previous version will govern the relationship prior
            to the User&apos;s acceptance. The User can obtain any previous
            version from the Owner.
          </Paragraph>
          <Paragraph marginBottom={20} medium>
            Assignment of contract
          </Paragraph>
          <Paragraph marginBottom={20}>
            The Owner reserves the right to transfer, assign, dispose of by
            novation, or subcontract any or all rights or obligations under
            these Terms, taking the User&apos;s legitimate interests into
            account. Provisions regarding changes of these Terms will apply
            accordingly.
          </Paragraph>
          <Paragraph marginBottom={56}>
            Users may not assign or transfer their rights or obligations under
            these Terms in any way, without the written permission of the Owner.
          </Paragraph>
          <Paragraph marginBottom={20} medium>
            Contacts
          </Paragraph>
          <Paragraph marginBottom={56}>
            All communications relating to the use of Retirable, Inc. must be
            sent using the contact information stated in this document.
          </Paragraph>
          <Paragraph marginBottom={20} medium>
            Severability
          </Paragraph>
          <Paragraph marginBottom={20}>
            Should any provision of these Terms be deemed or become invalid or
            unenforceable under applicable law, the invalidity or
            unenforceability of such provision shall not affect the validity of
            the remaining provisions, which shall remain in full force and
            effect.
          </Paragraph>
          <Paragraph medium marginBottom={20}>
            US Users
          </Paragraph>
          <Paragraph marginBottom={56}>
            Any such invalid or unenforceable provision will be interpreted,
            construed and reformed to the extent reasonably required to render
            it valid, enforceable and consistent with its original intent. These
            Terms constitute the entire Agreement between Users and the Owner
            with respect to the subject matter hereof, and supersede all other
            communications, including but not limited to all prior agreements,
            between the parties with respect to such subject matter. These Terms
            will be enforced to the fullest extent permitted by law.
          </Paragraph>
          <Paragraph marginBottom={20} medium>
            Governing law
          </Paragraph>
          <Paragraph marginBottom={56}>
            These Terms are governed by the law of the place where the Owner is
            based, as disclosed in the relevant section of this document,
            without regard to conflict of laws principles.
          </Paragraph>
          <Paragraph marginBottom={20} medium>
            Venue of jurisdiction
          </Paragraph>
          <Paragraph marginBottom={20}>
            The exclusive competence to decide on any controversy resulting from
            or connected to these Terms lies with the courts of the place where
            the Owner is based, as displayed in the relevant section of this
            document.
          </Paragraph>
          <Paragraph marginBottom={20} medium>
            US Users
          </Paragraph>
          <Paragraph>
            Each party specifically waives any right to trial by jury in any
            court in connection with any action or litigation.
          </Paragraph>
          <Paragraph marginBottom={56}>
            Any claims under these terms shall proceed individually and no party
            shall join in a class action or other proceeding with or on behalf
            of others.
          </Paragraph>
          <Paragraph medium marginBottom={20}>
            US Users
          </Paragraph>
          <Paragraph medium marginBottom={20}>
            Surviving provisions
          </Paragraph>
          <Paragraph>
            This Agreement shall continue in effect until it is terminated by
            either Retirable, Inc. or the User. Upon termination, the provisions
            contained in these Terms that by their context are intended to
            survive termination or expiration will survive, including but not
            limited to the following:
            <Ul>
              <Li>
                the User&apos;s grant of licenses under these Terms shall
                survive indefinitely;
              </Li>
              <Li>
                the User&apos;s indemnification obligations shall survive for a
                period of five years from the date of termination;
              </Li>
              <Li>
                the disclaimer of warranties and representations, and the
                stipulations under the section containing indemnity and
                limitation of liability provisions, shall survive indefinitely.
              </Li>
            </Ul>
          </Paragraph>
        </LegalSection>
        <LegalSection marginBottom={80}>
          <ExpandableSection>
            <ExpandableSectionHeading>
              Definitions and legal references
            </ExpandableSectionHeading>
            <ExpandableSectionBody>
              <Paragraph medium marginBottom={20}>
                Retirable, Inc. (or this Application)
              </Paragraph>
              <Paragraph marginBottom={32}>
                The property that enables the provision of the Service.
              </Paragraph>
              <Paragraph medium marginBottom={20}>
                Agreement
              </Paragraph>
              <Paragraph marginBottom={32}>
                Any legally binding or contractual relationship between the
                Owner and the User, governed by these Terms.
              </Paragraph>
              <Paragraph medium marginBottom={20}>
                Owner (or We)
              </Paragraph>
              <Paragraph marginBottom={32}>
                Indicates the natural person(s) or legal entity that provides
                Retirable, Inc. and/or the Service to Users.
              </Paragraph>
              <Paragraph medium marginBottom={20}>
                Service
              </Paragraph>
              <Paragraph marginBottom={32}>
                The service provided by Retirable, Inc. as described in these
                Terms and on Retirable, Inc..
              </Paragraph>
              <Paragraph medium marginBottom={20}>
                Terms
              </Paragraph>
              <Paragraph marginBottom={32}>
                All provisions applicable to the use of Retirable, Inc. and/or
                the Service as described in this document, including any other
                related documents or agreements, and as updated from time to
                time.
              </Paragraph>
              <Paragraph medium marginBottom={20}>
                User (or You)
              </Paragraph>
              <Paragraph marginBottom={32}>
                Indicates any natural person or legal entity using Retirable,
                Inc..
              </Paragraph>
              <Paragraph medium marginBottom={20}>
                Consumer
              </Paragraph>
              <Paragraph marginBottom={32}>
                Any User qualifying as a natural person who accesses goods or
                services for personal use, or more generally, acts for purposes
                outside their trade, business, craft or profession.
              </Paragraph>
            </ExpandableSectionBody>
          </ExpandableSection>
        </LegalSection>
      </LegalWrapper>
    </PageWrapper>
  </Layout>
);

export default TermsAndConditions;

export const Head = () => (
  <SEO
    title="Terms and Conditions — Retirable"
    description="View the terms and conditions for Retirable."
  />
);
